import React from "react"

import footerStyles from "../footer/footer.module.scss"

const footer = () => {
  const year = new Date().getFullYear()

  return (
    <footer className={footerStyles.footer}>
      <small className={footerStyles.copyright}>© Mo. | {year}</small>
    </footer>
  )
}

export default footer
